





































































import Vue from 'vue'

export default Vue.extend({
  props: {
    series: Array,
    categories: Array,
    formatter: Function,
    hideColumnTotals: Boolean,
  },
  computed: {
    hideRowTotals(): boolean {
      return this.series.length <= 1
    },
  },
  methods: {
    valueFn(input: { y: number }): number {
      return input.y
    },
  },
})
