


































import Vue, { PropType } from 'vue'

import { ApexOptions } from 'apexcharts'
import deepFreeze, { DeepReadonly } from 'deep-freeze'
import debounce from 'lodash.debounce'
import cloneDeep from 'lodash.clonedeep'
import log from '@/log'

// event bus
import MessageBus from '@/message-bus'

// api
import { PivotResult, NULL_QUERY_VALUE } from '@/api/types'

// components
import JChartWrapper from '@/components/controls/JChartWrapper.vue'
import PivotTable from '@/components/charts/PivotTable.vue'

// types
import {
  Query,
  ChartType,
  AxisType,
  FormatterFunction,
  FinderFunction,
  SortValueFn,
  DataLabelPosition,
  DEFAULT_AMOUNT_FORMATTER,
  DEFAULT_FINDER,
  DEFAULT_SORT_VALUE_FN,
} from '@/components/charts/chart-settings'

const FETCH_DEBOUNCE_WAIT = 250

const NULL_NAME = 'unknown'

export default Vue.extend({
  components: {
    JChartWrapper,
    PivotTable,
  },
  props: {
    hideChart: Boolean,

    chartType: {
      type: String as PropType<ChartType>,
      default: 'bar' as ChartType,
    },

    where: {
      type: Object as PropType<Query>,
      default: () => ({}),
    },

    title: String,
    height: [String, Number],

    horizontal: Boolean,
    stacked: Boolean,

    categories: {
      type: Array as PropType<(number | string)[]>,
      required: false,
    },
    amountField: {
      type: String,
      default: 'postedAmount',
    },
    amountLabel: String,
    hideAmountLabel: Boolean,
    amountFormatter: {
      type: Function as PropType<FormatterFunction>,
    },
    axisAmountFormatter: {
      type: Function as PropType<FormatterFunction>,
    },
    dataLabelAmountFormatter: {
      type: Function as PropType<FormatterFunction>,
    },
    rowField: {
      type: String,
    },
    rowLabel: String,
    hideRowLabel: Boolean,
    rowFinder: {
      type: Function as PropType<FinderFunction>,
    },
    axisType: {
      type: String as PropType<AxisType>,
      default: 'category' as AxisType,
    },
    columnField: {
      type: String,
    },
    columnFinder: {
      type: Function as PropType<FinderFunction>,
    },
    sortValueFn: {
      type: Function as PropType<SortValueFn>,
    },
    sortDesc: Boolean,
    showDataLabels: Boolean,

    xaxisAnnotations: {
      type: Array as PropType<XAxisAnnotations[]>,
      default: () => [],
    },
    yaxisAnnotations: {
      type: Array as PropType<YAxisAnnotations[]>,
      default: () => [],
    },
    pointAnnotations: {
      type: Array as PropType<PointAnnotations[]>,
      default: () => [],
    },

    cumulative: Boolean,
    showTable: Boolean,

    markersSize: Number,
    strokeCurve: String as PropType<'smooth' | 'straight' | 'stepline'>,
    strokeWidth: Number,
    strokeDashArray: Number,
  },
  data() {
    return {
      result: undefined as DeepReadonly<PivotResult> | undefined,

      fetchingCount: 0,
      errors: [] as Error[],

      dialogQuery: undefined as Query | undefined,

      fetchData: debounce((this as any).doFetch, FETCH_DEBOUNCE_WAIT), // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  },
  watch: {
    isFetching(value: boolean) {
      this.$emit('loading', value)
    },
    fetchCriteria: {
      immediate: true,
      deep: false,
      handler(value, oldValue): void {
        if (value === undefined) {
          this.errors.push(Error('quota data missing'))
        } else if (
          oldValue !== undefined &&
          value.amountField === oldValue.amountField &&
          value.rowField === oldValue.rowField &&
          value.columnField === oldValue.columnField &&
          Object.keys(value.query).length ===
            Object.keys(oldValue.query).length &&
          Object.keys(value.query).every(
            key => value.query[key] === oldValue.query[key]
          )
        ) {
          log.info('ignorings...no changes made')
        } else {
          this.fetchData()
        }
      },
    },
  },
  computed: {
    hasData(): boolean {
      return !!this.result && !!this.result.data && this.result.data.length > 0
    },
    isFetching(): boolean {
      return !!this.fetchingCount
    },
    fetchCriteria(): unknown {
      return {
        amountField: this.amountField,
        rowField: this.rowField,
        columnField: this.columnField,
        query: this.query,
      }
    },
    query(): Query {
      return {
        ...this.where,
      }
    },
    dataLabelPosition(): DataLabelPosition {
      const combo = [this.horizontal, this.stacked]
        .map(z => (z ? 1 : 0).toString())
        .join('')

      switch (combo) {
        case '11':
        case '10':
          // horizontal bars => label at end (right) of bar
          return {
            position: 'center',
            textAnchor: 'middle',
          }
        case '01':
          // vertical stacked bars => labels above top bar (sum of bars)
          return {
            position: 'top',
            offsetY:
              this.chartType === 'bar'
                ? (12 * 1.5 + 4) * -1
                : this.chartType === 'area'
                ? 8 * -1
                : 0,
          }
        case '00':
          return {
            position: 'top',
          }
        default:
          return {}
      }
    },
    amountLabelValue(): string | undefined {
      if (this.hideAmountLabel) return undefined
      return this.amountLabel || this.amountField
    },
    rowLabelValue(): string | undefined {
      if (this.hideRowLabel) return undefined
      return this.rowLabel || this.rowField
    },
    // use for tooltip formatter
    amountFormatFn(): FormatterFunction {
      return this.amountFormatter || DEFAULT_AMOUNT_FORMATTER
    },
    dataLabelAmountFormatFn(): FormatterFunction {
      return this.dataLabelAmountFormatter || this.amountFormatFn
    },
    axisAmountFormatFn(): FormatterFunction {
      return this.axisAmountFormatter || this.amountFormatFn
    },
    series(): ApexAxisChartSeries {
      // make sure result is set
      if (!this.result) return []
      const result = this.result

      // set name finder function
      const nameFinder = (id: string | number | null, index: number) => {
        if (id === null || id === undefined) return `Series #${index + 1}`
        const value = (this.columnFinder || DEFAULT_FINDER)(id)
        if (value === null || value === undefined) return `Series #${index + 1}`
        return value.toString()
      }

      // set default for null values
      const emptyValue = 0 // null

      // set value function (for cumulative)
      const valueFn = this.cumulative
        ? (value: number, prev: number[]) =>
            (prev[prev.length - 1] || 0) + value
        : (value: number) => value

      return result.columns.map((columnId, c) => {
        return {
          name: nameFinder(columnId, c),

          data: this.sortedCategoryIndexes.reduce((prev, rowIndex, i) => {
            const value =
              rowIndex >= 0
                ? result.data[rowIndex][c] || emptyValue
                : emptyValue

            return [
              ...prev,
              {
                x: this.categoryNames[i],
                y: valueFn(
                  value,
                  prev.map(p => p.y)
                ),
              },
            ]
          }, [] as Array<{ x: string; y: number }>),
        }
      })
    },
    sortedCategoryIndexes(): number[] {
      if (!this.result) return []
      const result = this.result

      if (this.categories && this.categories.length > 0) {
        return this.categories.map(c => result.rows.indexOf(c))
      }

      const mult = this.sortDesc ? -1 : 1 // -1 for descending
      const valueFn = this.sortValueFn || DEFAULT_SORT_VALUE_FN

      return Array.from(Array(result.rows.length).keys()).sort((a, b) => {
        const aValue = valueFn(result.data[a], result.rows[a])
        const bValue = valueFn(result.data[b], result.rows[b])
        return (aValue < bValue ? -1 : bValue < aValue ? 1 : 0) * mult
      })
    },
    categoryNames(): string[] {
      if (!this.result) return []
      const result = this.result

      if (this.categories && this.categories.length > 0) {
        return this.categories.map(c => this.rowFinder(c).toString())
      }

      return this.sortedCategoryIndexes.map(index =>
        (this.rowFinder(result.rows[index]) || NULL_NAME).toString()
      )
    },
    chartHeight(): string | number {
      // eslint-disable-next-line prettier/prettier
      return this.height
        ? this.height
        : this.horizontal
        ? this.categoryNames.length *
            (this.stacked ? 1 : this.series.length) *
            16 +
          96 +
          (this.hideAmountLabel ? 0 : 16)
        : 'auto'
    },
    chartOptions(): ApexOptions {
      return {
        chart: {
          type: this.chartType,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
          stacked: this.stacked,
          events: {
            click: (event, chartContext, options) => {
              if (
                options.seriesIndex >= 0 &&
                options.dataPointIndex >= 0 &&
                this.result
              ) {
                const result = this.result
                const rowId =
                  result.rows[
                    this.sortedCategoryIndexes[options.dataPointIndex]
                  ]

                const fullWhere = this.query
                fullWhere[this.rowField] =
                  rowId === null ? NULL_QUERY_VALUE : rowId

                if (this.columnField) {
                  const columnId = result.columns[options.seriesIndex]

                  if (columnId || columnId === 0) {
                    fullWhere[this.columnField] = columnId
                  } else {
                    // TODO: filter for NULL conditions
                    fullWhere[this.columnField] = NULL_QUERY_VALUE
                  }
                }

                this.dialogQuery = fullWhere
              }
            },
          },
        },
        legend: {
          position: this.horizontal ? 'right' : 'bottom',
          showForSingleSeries: false,
          showForNullSeries: false,
          showForZeroSeries: false,
          width: 128,
        },
        plotOptions: {
          bar: {
            horizontal: this.horizontal,
            dataLabels: {
              position: this.dataLabelPosition.position,
            },
          },
        },
        title: {
          text: this.title,
          align: 'left',
        },
        // yaxis or xaxis (for horizontal)
        xaxis: {
          type: this.axisType,
          categories: this.categoryNames,
          labels: {
            formatter: this.horizontal
              ? value => this.axisAmountFormatFn(Number(value || 0)).toString()
              : undefined,
          },
          title: {
            text: this.horizontal ? this.amountLabelValue : this.rowLabelValue,
          },
        },
        yaxis: {
          forceNiceScale: true,
          max: (max: number) =>
            Math.max(
              max,
              this.yaxisAnnotations.reduce(
                (prev, ann) =>
                  Math.max(
                    prev,
                    Math.max(Number(ann.y || 0), Number(ann.y2 || ann.y || 0))
                  ),
                0
              )
            ),
          labels: {
            formatter: this.horizontal
              ? undefined
              : value => this.axisAmountFormatFn(Number(value || 0)).toString(),
          },
          title: {
            text: this.horizontal ? this.rowLabelValue : this.amountLabelValue,
          },
        },
        annotations: {
          position: 'back',
          xaxis: this.xaxisAnnotations,
          yaxis: this.yaxisAnnotations,
          points: this.pointAnnotations,
        },
        markers: {
          size: this.markersSize || 0,
          hover: {
            sizeOffset: 3,
          },
        },
        stroke: {
          curve: this.strokeCurve || 'smooth',
          width:
            this.strokeWidth || ['line', 'area'].includes(this.chartType)
              ? 4
              : 0,
          dashArray: this.strokeDashArray || 0,
        },
        dataLabels: {
          enabled: this.showDataLabels,
          textAnchor: this.dataLabelPosition.textAnchor || 'middle',
          offsetX: this.dataLabelPosition.offsetX || 0,
          offsetY: this.dataLabelPosition.offsetY || 0,
          style: {
            colors: [this.horizontal ? '#ffffff' : 'rgba(0, 0, 0, 0.87)'],
            fontWeight: 'normal',
            fontSize: this.horizontal ? '11px' : '12px',
          },
          background: {
            enabled: false,
            dropShadow: {},
          },
          formatter:
            this.chartType === 'treemap'
              ? value => value
              : !this.horizontal && this.stacked
              ? (value, { seriesIndex, dataPointIndex, w }) => {
                  if (seriesIndex === w.config.series.length - 1) {
                    return this.dataLabelAmountFormatFn(
                      w.globals.stackedSeriesTotals[dataPointIndex]
                    )
                  } else {
                    return ''
                  }
                }
              : value => {
                  log.info(value)
                  return this.dataLabelAmountFormatFn(value)
                },
        },
        tooltip: {
          y: {
            formatter: value => this.amountFormatFn(value).toString(),
          },
        },
      }
    },
  },
  methods: {
    refresh(): void {
      this.doFetch()
    },
    doFetch() {
      this.fetchingCount++
      this.errors = []

      this.$api.charts.pivot
        .get(this.amountField, this.rowField, this.columnField, this.query)
        .then(result => {
          this.result = deepFreeze(result)
        })
        .catch(err => {
          MessageBus.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.fetchingCount--
        })
    },
    onPivotTableClick(v: {
      seriesIndex?: number
      categoryIndex?: number
    }): void {
      if (this.result) {
        const result = this.result
        const fullWhere = cloneDeep(this.query)

        if (
          Object.prototype.hasOwnProperty.call(v, 'categoryIndex') &&
          v.categoryIndex !== undefined
        ) {
          const rowId = result.rows[this.sortedCategoryIndexes[v.categoryIndex]]
          fullWhere[this.rowField] = rowId
        }

        if (
          this.columnField &&
          Object.prototype.hasOwnProperty.call(v, 'seriesIndex') &&
          v.seriesIndex !== undefined
        ) {
          const columnId = result.columns[v.seriesIndex]

          if (columnId) {
            fullWhere[this.columnField] = columnId
          } else {
            // TODO: filter for NULL conditions
            fullWhere[this.columnField] = NULL_QUERY_VALUE
          }
        }

        this.dialogQuery = fullWhere
      }
    },
  },
})
